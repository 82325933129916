/* global window, document, $, i18next, i18nextHttpBackend, jqueryI18next */
const maxScroll = 100;
let lastScroll = 0;

window.onload = function () {
    try {
        i18next.use(i18nextHttpBackend);
        i18next.init({
            'debug': false,
            'lng': 'en',
            'fallbackLng': 'en',
            'backend': {
                'loadPath': '../locales/{{lng}}/{{ns}}.json'
            }
        }, function () {
            jqueryI18next.init(i18next, $);
            $('body').localize();
        });
    } catch (err) {
        console.warn('Unable to complete translations. Default to locale EN.');
    }

    window.scrollTo(0, 0);
};

window.onscroll = function () {
    const currentScroll = document.body.scrollTop || document.documentElement.scrollTop;

    if (currentScroll > maxScroll) {
        if (lastScroll <= maxScroll) {
            // Resize Bigger
            $('#primary-navigation .company-logo').css('height', '2.5rem');
            $('#primary-navigation a.nav-link').css('font-size', '1rem');
            $('#primary-navigation .navbar-brand').css('padding', '0.5rem');
            $('#primary-navigation .driver-login a').css('padding', '0.5rem');
        }
    } else {
        if (lastScroll >= maxScroll) {
            // Resize smaller
            $('#primary-navigation .company-logo').css('height', '4rem');
            $('#primary-navigation a.nav-link').css('font-size', '1.05rem');
            $('#primary-navigation .navbar-brand').css('padding', '1.2rem');
            $('#primary-navigation .driver-login a').css('padding', '1rem');
        }
    }
    lastScroll = currentScroll;
};

// compat shims
if (!('objectFit' in document.documentElement.style)) {

    $('.image-container.compat-check').each(function () {
        const container = $(this);
        const imgUrl = container.find('img').prop('src');
        if (imgUrl) {
            container.css('backgroundImage', 'url(' + imgUrl + ')').addClass('compat-object-fit');
        }
    });

    $('footer.page-footer').css('minHeight', '460px');
}
